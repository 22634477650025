import { createStore } from 'vuex'
import videoPlayer from './videoPlayer'
import subtitles from './subtitles';
import videoSetting from './videoSetting';
import dialogVideoPlayer from './dialogVideoPlayer';
import dialogSubtitles from './dialogSubtitles';
import videoList from './videoList';

// 创建一个新的 store 实例
const store = createStore({
    modules: {
        videoPlayer,
        subtitles,
        videoSetting,
        dialogVideoPlayer,
        dialogSubtitles,
        videoList
    }
});
export default store