<template>
  <div v-if="dialogVisible" class="dialog-container">
    <div v-if="modal" class="dialog-overlay" @click="handleOverlayClick"></div>
    <div 
      ref="dialogWrapperEl"
      class="dialog-wrapper" 
      :class="[customClass, { 'is-center': center }]"
      :style="{ width, height }"
      @mousedown="handleMouseDown"
    >
      <div v-if="resizable" class="resize-handles">
        <div class="resize-handle top" data-resize="top"></div>
        <div class="resize-handle right" data-resize="right"></div>
        <div class="resize-handle bottom" data-resize="bottom"></div>
        <div class="resize-handle left" data-resize="left"></div>
        <div class="resize-handle top-left" data-resize="top-left"></div>
        <div class="resize-handle top-right" data-resize="top-right"></div>
        <div class="resize-handle bottom-left" data-resize="bottom-left"></div>
        <div class="resize-handle bottom-right" data-resize="bottom-right"></div>
      </div>

      <div
        v-if="showHeader"
        ref="dialogHeaderEl"
        class="dialog-header"
      >
        {{ title }}
        <button v-if="showClose" class="dialog-close" @click="handleClose">
          ✕
        </button>
      </div>

      <div class="dialog-body">
        <slot></slot>
      </div>

      <div v-if="showFooter" class="dialog-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '50%'
  },
  height: {
    type: String,
    default: 'auto'
  },
  modal: {
    type: Boolean,
    default: true
  },
  closeOnClickModal: {
    type: Boolean,
    default: true
  },
  showClose: {
    type: Boolean,
    default: true
  },
  center: {
    type: Boolean,
    default: false
  },
  destroyOnClose: {
    type: Boolean,
    default: false
  },
  customClass: {
    type: String,
    default: ''
  },
  draggable: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  resizable: {
    type: Boolean,
    default: true
  },
  minWidth: {
    type: Number,
    default: 200
  },
  minHeight: {
    type: Number,
    default: 100
  }
  // 移除 aspectRatio 和 lockAspectRatio
})

const emit = defineEmits(['update:visible', 'open', 'close'])

const dialogVisible = ref(props.visible) // 修改这里，使用 props.visible 作为初始值

// 监听visible属性变化
watch(() => props.visible, (val) => {
  dialogVisible.value = val
}, { immediate: true }) // 添加 immediate: true，确保首次渲染时同步

const dialogHeaderEl = ref(null)
const dialogWrapperEl = ref(null)

// 监听visible属性变化
watch(() => props.visible, (val) => {
  dialogVisible.value = val
})

// 监听dialogVisible变化
watch(() => dialogVisible.value, (val) => {
  emit('update:visible', val)
  if (val) {
    handleOpen()  // 当对话框显示时触发 open 事件
  }
})

// 处理对话框打开事件
const handleOpen = () => {
  emit('open')
}

// 处理对话框关闭事件
const handleClose = () => {
  dialogVisible.value = false
  emit('close')
}

// 拖拽相关
let dragState = {
  startMouseLeft: 0,
  startMouseTop: 0,
  startDialogLeft: 0,
  startDialogTop: 0,
  isDragging: false,  // 添加拖拽标记
  startTime: 0       // 添加开始时间
}

const handleMouseDown = (e) => {
  if (!props.draggable) return
  
  // 如果点击的是关闭按钮，不进行拖拽
  if (e.target.classList.contains('dialog-close')) return

  const dialog = dialogWrapperEl.value
  const dialogRect = dialog.getBoundingClientRect()

  dragState = {
    startMouseLeft: e.clientX,
    startMouseTop: e.clientY,
    startDialogLeft: dialogRect.left,
    startDialogTop: dialogRect.top,
    isDragging: false,
    startTime: Date.now()
  }

  // 绑定事件到 document
  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
}

const handleMouseMove = (e) => {
  const moveX = Math.abs(e.clientX - dragState.startMouseLeft)
  const moveY = Math.abs(e.clientY - dragState.startMouseTop)
  
  // 如果移动距离大于 3px，则认为是拖拽
  if (moveX > 3 || moveY > 3) {
    dragState.isDragging = true
    
    // 只有在确认是拖拽时才添加遮罩层
    if (!dragState.overlay) {
      const overlay = document.createElement('div')
      overlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2001;
        cursor: move;
        user-select: none;
      `
      document.body.appendChild(overlay)
      dragState.overlay = overlay
    }
    
    const dialog = dialogWrapperEl.value
    const moveLeft = e.clientX - dragState.startMouseLeft
    const moveTop = e.clientY - dragState.startMouseTop

    dialog.style.transform = 'none'
    dialog.style.position = 'fixed'
    dialog.style.margin = '0'
    dialog.style.left = `${dragState.startDialogLeft + moveLeft}px`
    dialog.style.top = `${dragState.startDialogTop + moveTop}px`
  }
}

const handleMouseUp = (e) => {
  // 如果不是拖拽且时间小于 200ms，则视为点击
  const isClick = !dragState.isDragging && (Date.now() - dragState.startTime < 200)
  
  if (dragState.overlay) {
    document.body.removeChild(dragState.overlay)
  }
  
  document.removeEventListener('mousemove', handleMouseMove)
  document.removeEventListener('mouseup', handleMouseUp)

  // 如果是点击事件，不阻止事件传播
  if (!isClick) {
    e.preventDefault()
    e.stopPropagation()
  }

  // 重置拖拽状态
  dragState = {
    startMouseLeft: 0,
    startMouseTop: 0,
    startDialogLeft: 0,
    startDialogTop: 0,
    isDragging: false,
    startTime: 0
  }
}

const handleOverlayClick = () => {
  if (props.closeOnClickModal) {
    handleClose()
  }
}

// 调整大小相关
let resizeState = {
  isResizing: false,
  direction: '',
  startX: 0,
  startY: 0,
  startWidth: 0,
  startHeight: 0,
  startTop: 0,
  startLeft: 0
}

const handleResizeStart = (e) => {
  const resizeHandle = e.target.closest('.resize-handle')
  if (!resizeHandle) return

  e.preventDefault()
  e.stopPropagation()

  const dialog = dialogWrapperEl.value
  const rect = dialog.getBoundingClientRect()

  resizeState = {
    isResizing: true,
    direction: resizeHandle.dataset.resize,
    startX: e.clientX,
    startY: e.clientY,
    startWidth: rect.width,
    startHeight: rect.height,
    startTop: rect.top,
    startLeft: rect.left
  }

  document.addEventListener('mousemove', handleResizeMove)
  document.addEventListener('mouseup', handleResizeEnd)
}

const handleResizeMove = (e) => {
  if (!resizeState.isResizing) return

  const dialog = dialogWrapperEl.value
  const deltaX = e.clientX - resizeState.startX
  const deltaY = e.clientY - resizeState.startY

  let newWidth = resizeState.startWidth
  let newHeight = resizeState.startHeight
  let newTop = resizeState.startTop
  let newLeft = resizeState.startLeft

  // 简化尺寸计算逻辑
  const calculateDimensions = (width, height) => {
    return {
      width: Math.max(props.minWidth, width),
      height: Math.max(props.minHeight, height)
    }
  }

  let dims;
  switch (resizeState.direction) {
    case 'right':
      dims = calculateDimensions(resizeState.startWidth + deltaX, resizeState.startHeight)
      newWidth = dims.width
      break;
    case 'left':
      dims = calculateDimensions(resizeState.startWidth - deltaX, resizeState.startHeight)
      newWidth = dims.width
      newLeft = resizeState.startLeft + resizeState.startWidth - newWidth
      break;
    case 'bottom':
      dims = calculateDimensions(resizeState.startWidth, resizeState.startHeight + deltaY)
      newHeight = dims.height
      break;
    case 'top':
      dims = calculateDimensions(resizeState.startWidth, resizeState.startHeight - deltaY)
      newHeight = dims.height
      newTop = resizeState.startTop + resizeState.startHeight - newHeight
      break;
    case 'top-left':
      dims = calculateDimensions(resizeState.startWidth - deltaX, resizeState.startHeight - deltaY)
      newWidth = dims.width
      newHeight = dims.height
      newLeft = resizeState.startLeft + resizeState.startWidth - newWidth
      newTop = resizeState.startTop + resizeState.startHeight - newHeight
      break;
    case 'top-right':
      dims = calculateDimensions(resizeState.startWidth + deltaX, resizeState.startHeight - deltaY)
      newWidth = dims.width
      newHeight = dims.height
      newTop = resizeState.startTop + resizeState.startHeight - newHeight
      break;
    case 'bottom-left':
      dims = calculateDimensions(resizeState.startWidth - deltaX, resizeState.startHeight + deltaY)
      newWidth = dims.width
      newHeight = dims.height
      newLeft = resizeState.startLeft + resizeState.startWidth - newWidth
      break;
    case 'bottom-right':
      dims = calculateDimensions(resizeState.startWidth + deltaX, resizeState.startHeight + deltaY)
      newWidth = dims.width
      newHeight = dims.height
      break;
  }

  // 应用新的尺寸和位置
  dialog.style.width = `${newWidth}px`
  dialog.style.height = `${newHeight}px`
  dialog.style.top = `${newTop}px`
  dialog.style.left = `${newLeft}px`
}

const handleResizeEnd = () => {
  resizeState.isResizing = false
  document.removeEventListener('mousemove', handleResizeMove)
  document.removeEventListener('mouseup', handleResizeEnd)
}

// 在组件挂载时添加事件监听
onMounted(() => {
  const dialog = dialogWrapperEl.value
  if (dialog && props.resizable) {
    dialog.addEventListener('mousedown', handleResizeStart)
  }
})

// 在组件卸载时移除事件监听
onUnmounted(() => {
  const dialog = dialogWrapperEl.value
  if (dialog) {
    dialog.removeEventListener('mousedown', handleResizeStart)
  }
})
</script>

<style lang="scss" scoped>
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;
}

.dialog-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 2002;
  max-width: 90%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  transition: none;
  cursor: move;  // 添加移动光标

  &.is-center {
    text-align: center;
  }

  &[style*="position: fixed"] {
    transition: none;
  }

  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100px;

  // 防止调整大小时闪烁
  will-change: width, height, top, left;
}

.dialog-header {
  cursor: move;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  background: #f5f7fa;
  user-select: none;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.dialog-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  background: none;
  border: none;
  padding: 4px 8px;
  color: #909399;
  
  &:hover {
    color: var(--el-color-primary);
  }
}

.dialog-body {
  // padding: 20px;
  overflow-y: auto;
  flex: 1;
  position: relative;
}

.dialog-footer {
  padding: 20px;
  text-align: right;
  box-sizing: border-box;
  border-top: 1px solid #dcdfe6;
}

.resize-handles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.resize-handle {
  position: absolute;
  pointer-events: auto;
  background: transparent; // 添加透明背景，提升用户体验

  &.top {
    top: -4px;
    left: 4px;
    right: 4px;
    height: 8px; // 增加高度
    cursor: n-resize;
  }

  &.right {
    top: 4px;
    right: -4px;
    bottom: 4px;
    width: 8px; // 增加宽度
    cursor: e-resize;
  }

  &.bottom {
    bottom: -4px;
    left: 4px;
    right: 4px;
    height: 8px; // 增加高度
    cursor: s-resize;
  }

  &.left {
    top: 4px;
    left: -4px;
    bottom: 4px;
    width: 8px; // 增加宽度
    cursor: w-resize;
  }

  &.top-left {
    top: -4px;
    left: -4px;
    width: 8px; // 增加尺寸
    height: 8px;
    cursor: nw-resize;
  }

  &.top-right {
    top: -4px;
    right: -4px;
    width: 8px; // 增加尺寸
    height: 8px;
    cursor: ne-resize;
  }

  &.bottom-left {
    bottom: -4px;
    left: -4px;
    width: 8px; // 增加尺寸
    height: 8px;
    cursor: sw-resize;
  }

  &.bottom-right {
    bottom: -4px;
    right: -4px;
    width: 8px; // 增加尺寸
    height: 8px;
    cursor: se-resize;
  }

  // 添加悬停效果
  &:hover {
    background: rgba(0, 0, 0, 0.1); // 悬停时显示半透明背景
  }
}
</style>