import { DialogService } from '@/service/DialogService'

export default {
  install: (app) => {
    // 初始化 DialogService
    DialogService.init(app)

    // 添加全局属性
    app.config.globalProperties.$dialog = DialogService

    // 添加到 window 对象，方便在非组件中调用
    window.$dialog = DialogService
  }
} 