import PLAY_STATE, { ModeType } from "@/contants/playState";

// 判断值是否为有效的数值（包括0）
const isValidNumber = (value) => {
  // 检查是否为数字且不是NaN
  return (
    (typeof value === "number" && !isNaN(value)) ||
    // 或者能被成功转换为数字
    (value !== null && value !== undefined && !isNaN(Number(value)))
  );
};

const videoPlayer = {
  namespaced: true,
  state() {
    return {
      currentVideoInfo: localStorage.getItem("currentVideoInfo")
        ? JSON.parse(localStorage.getItem("currentVideoInfo"))
        : {},
      playState: PLAY_STATE.loading,
      playRate: 1,
      currentTime: 0,
      player: null,
      bufferTime: 0,
      modeType: ModeType.all,
      showVideoControls: false,
      // 视频状态: stopped, playing, ended
      videoState: "stopped",
      // 是否禁用快捷键
      isDisabledShortcuts: false,
    };
  },
  getters: {
    playBtnIcon: (state) => {
      if (state.playState === PLAY_STATE.paused) return "icon-kaishi";
      if (state.playState === PLAY_STATE.ended) return "icon-xunhuan";
      return "icon-zanting1";
    },
  },
  mutations: {
    setPlayer(state, player) {
      state.player = player;
    },
    setVideoInfo(state, info) {
      state.currentVideoInfo = {
        ...state.currentVideoInfo,
        ...info,
      };
      localStorage.setItem(
        "currentVideoInfo",
        JSON.stringify(state.currentVideoInfo)
      );
    },
    changeVideoInfo(state, info) {
      state.currentVideoInfo = info;
      localStorage.setItem("currentVideoInfo", JSON.stringify(info));
    },
    changePlayState(state, playState) {
      if (
        playState === PLAY_STATE.loaded &&
        state.playState === PLAY_STATE.play
      ) {
        state.playState = PLAY_STATE.play; // fix ios 状态特殊情况：先播放后load结束
      } else {
        state.playState = playState;
      }

      // 根据播放状态更新videoState
      if (playState === PLAY_STATE.play) {
        state.videoState = "playing";
        state.showVideoControls = false;
      } else if (playState === PLAY_STATE.ended) {
        state.videoState = "ended";
        state.showVideoControls = true;
      } else if (playState === PLAY_STATE.paused) {
        // 暂停时不一定要改变视频状态，可能只是临时暂停
      }
    },
    // 设置视频状态
    setVideoState(state, videoState) {
      state.videoState = videoState;
    },
    changePlayRate(state, playRate) {
      state.playRate = playRate;
    },
    changeUserPlayRate(state, playRate) {
      state.userPlayState = playRate;
    },
    changeCurrentTime(state, currentTime) {
      state.currentTime = currentTime;
    },
    changeBufferTime(state, bufferTime) {
      state.bufferTime = bufferTime;
    },
    changeModeType(state, modeType) {
      state.modeType = modeType;
    },
    setShowVideoControls(state, show) {
      state.showVideoControls = show;
    },
    setDisabledShortcuts(state, isDisabled) {
      state.isDisabledShortcuts = isDisabled;
    },
  },
  actions: {
    // 处理播放或重播逻辑
    handlePlayOrReplay({ state, commit }) {
      const player = state.player;
      if (!player) return;

      // 如果视频正在播放，则暂停
      if (state.playState === PLAY_STATE.play) {
        player.pause();
        return;
      }

      // 如果视频已暂停或未开始播放，处理播放逻辑
      if (
        state.playState === PLAY_STATE.paused ||
        state.playState === PLAY_STATE.loading ||
        state.playState === PLAY_STATE.loaded
      ) {
        // 隐藏控制按钮
        commit("setShowVideoControls", false);
        commit("setVideoState", "playing");

        // 检查是否有有效的起始时间
        const videoInfo = state.currentVideoInfo;
        const hasStartTime =
          isValidNumber(videoInfo.startTime) ||
          isValidNumber(videoInfo.start_time);

        // 如果设置了有效的起始时间，且当前时间接近0，先跳转到起始时间
        if (hasStartTime && player.currentTime < 1) {
          const startTime = isValidNumber(videoInfo.startTime)
            ? Number(videoInfo.startTime)
            : Number(videoInfo.start_time);
          console.log("设置视频播放起始时间:", startTime);
          player.currentTime = startTime;
        }

        // 开始播放
        player.play().catch((err) => {
          console.error("播放视频失败:", err);
        });
      }

      // 如果视频已结束，处理重播逻辑
      if (state.playState === PLAY_STATE.ended) {
        // 隐藏控制按钮
        commit("setShowVideoControls", false);
        commit("setVideoState", "playing");

        // 获取视频的起始时间
        const videoInfo = state.currentVideoInfo;
        const startTime = isValidNumber(videoInfo.startTime)
          ? Number(videoInfo.startTime)
          : isValidNumber(videoInfo.start_time)
          ? Number(videoInfo.start_time)
          : 0;

        console.log("重新播放视频，起始时间:", startTime);

        // 重新设置播放位置到起始时间
        player.currentTime = startTime;

        // 开始播放
        player.play().catch((err) => {
          console.error("重播视频失败:", err);
        });
      }
    },
  },
};

export default videoPlayer;
