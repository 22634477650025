import PLAY_STATE, { ModeType } from "@/contants/playState";
import emitter from "@/utils/eventBus";
import EVENTS from "@/contants/events";

// 判断值是否为有效的数值（包括0）
const isValidNumber = (value) => {
  // 检查是否为数字且不是NaN
  return (
    (typeof value === "number" && !isNaN(value)) ||
    // 或者能被成功转换为数字
    (value !== null && value !== undefined && !isNaN(Number(value)))
  );
};

// 获取带命名空间的事件名称
const getDialogEventName = (eventName) => `dialog:${eventName}`;

const dialogVideoPlayer = {
  namespaced: true,
  state() {
    return {
      // 当前播放的视频信息
      currentVideoInfo: {},
      // 视频播放列表
      videoList: [],
      // 当前播放列表索引
      currentVideoIndex: 0,
      // 播放状态
      playState: PLAY_STATE.loading,
      // 播放速率
      playRate: 1,
      // 当前播放时间
      currentTime: 0,
      // 当前进度 (0-1之间)
      progress: 0,
      // 播放器实例引用
      player: null,
      // 缓冲时间
      bufferTime: 0,
      // 播放模式
      modeType: ModeType.all,
      // 是否为播放列表模式
      isPlaylistMode: false,
      // 是否自动播放
      autoPlay: true,
      // 是否自动播放下一个视频
      autoPlayNext: true,
      // 视频状态 (stopped, playing, ended)
      videoState: "stopped",
      // 是否显示控制按钮
      showVideoControls: false,
      // 是否显示蒙版
      isMaskVisible: false,
      // 是否处于全屏模式
      isFullscreen: false,
      // 是否显示控制栏
      isControlBarVisible: true,
      // 视频加载中
      isVideoLoading: false,
      // 音量
      volume: 1,
      // 是否静音
      isMuted: false,
      // 从配置中获取seekStep，默认为5秒
      seekStep: 5,
    };
  },
  mutations: {
    // 设置播放器实例
    setPlayer(state, player) {
      state.player = player;
    },

    // 设置当前视频信息
    setCurrentVideoInfo(state, info) {
      state.currentVideoInfo = info;
    },

    // 设置视频列表
    setVideoList(state, list) {
      state.videoList = list;
      state.isPlaylistMode = list.length > 1;
    },

    // 设置当前视频索引
    setCurrentVideoIndex(state, index) {
      state.currentVideoIndex = index;
    },

    // 更新播放状态
    changePlayState(state, playState) {
      if (
        playState === PLAY_STATE.loaded &&
        state.playState === PLAY_STATE.play
      ) {
        state.playState = PLAY_STATE.play; // 修复iOS状态特殊情况：先播放后load结束
      } else {
        state.playState = playState;
      }

      // 根据播放状态更新videoState
      if (playState === PLAY_STATE.play) {
        state.videoState = "playing";
      } else if (playState === PLAY_STATE.ended) {
        state.videoState = "ended";
      } else if (playState === PLAY_STATE.loading) {
        // 保持当前状态不变
        state.isVideoLoading = true;
      } else if (playState === PLAY_STATE.loaded) {
        state.isVideoLoading = false;
      } else if (playState === PLAY_STATE.paused) {
        // 暂停时不改变videoState，因为用户可能只是临时暂停
      }
    },

    // 直接设置视频状态（stopped, playing, ended）
    setVideoState(state, videoState) {
      console.log("收到视频播放事件,更新store视频状态:", videoState);
      state.videoState = videoState;

      // 根据视频状态和自动播放设置决定是否显示控制按钮
      if (!state.autoPlay && videoState === "stopped") {
        state.showVideoControls = true;
      } else if (!state.autoPlayNext && videoState === "ended") {
        state.showVideoControls = true;
      } else {
        state.showVideoControls = false;
      }
    },

    // 更新播放速率
    changePlayRate(state, playRate) {
      state.playRate = playRate;
    },

    // 更新当前播放时间
    changeCurrentTime(state, currentTime) {
      state.currentTime = currentTime;
    },

    // 设置播放进度 (0-1)
    setProgress(state, progress) {
      // 确保进度在0-1之间
      state.progress = Math.max(0, Math.min(1, progress));
    },

    // 更新缓冲时间
    changeBufferTime(state, bufferTime) {
      state.bufferTime = bufferTime;
    },

    // 更新播放模式
    changeModeType(state, modeType) {
      state.modeType = modeType;
    },

    // 设置自动播放
    setAutoPlay(state, autoPlay) {
      state.autoPlay = autoPlay;

      // 更新控制按钮显示状态
      if (!autoPlay && state.videoState === "stopped") {
        state.showVideoControls = true;
      } else {
        state.showVideoControls = false;
      }
    },

    // 设置自动播放下一个视频
    setAutoPlayNext(state, autoPlayNext) {
      state.autoPlayNext = autoPlayNext;
    },

    // 设置控制按钮显示状态
    setShowVideoControls(state, show) {
      state.showVideoControls = show;
    },

    // 设置蒙版可见状态
    setMaskVisible(state, visible) {
      state.isMaskVisible = visible;
    },

    // 设置全屏状态
    setFullscreen(state, isFullscreen) {
      state.isFullscreen = isFullscreen;
    },

    // 设置控制栏可见状态
    setControlBarVisible(state, visible) {
      state.isControlBarVisible = visible;
    },

    // 设置视频加载状态
    setVideoLoading(state, isLoading) {
      state.isVideoLoading = isLoading;
    },

    // 设置音量
    setVolume(state, volume) {
      // 确保音量在0-1之间
      state.volume = Math.max(0, Math.min(1, volume));

      // 如果音量为0，则设置为静音状态
      state.isMuted = state.volume === 0;

      // 如果播放器存在，设置播放器音量
      if (state.player) {
        state.player.volume = state.volume;
      }
    },

    // 设置静音状态
    setMuted(state, isMuted) {
      state.isMuted = isMuted;

      // 如果播放器存在，设置播放器音量
      if (state.player) {
        state.player.volume = isMuted ? 0 : state.volume;
      }
    },

    // 设置前进后退步长
    setSeekStep(state, seekStep) {
      // 确保seekStep是正数且有默认值
      state.seekStep = seekStep !== undefined && seekStep > 0 ? seekStep : 5;
    },
  },
  actions: {
    // 播放视频
    playVideo({ state, commit }) {
      if (!state.player) return;

      state.player
        .play()
        .then(() => {
          commit("setVideoState", "playing");
          commit("setShowVideoControls", false);
          // 触发播放事件，使用命名空间
          emitter.emit(getDialogEventName(EVENTS.PLAY));
        })
        .catch((err) => {
          console.error("视频播放失败:", err);
        });
    },

    // 暂停视频
    pauseVideo({ state }) {
      if (!state.player) return;
      state.player.pause();
      // 触发暂停事件，使用命名空间
      emitter.emit(getDialogEventName(EVENTS.PAUSE));
    },

    // 跳转到指定时间
    seekTo({ state, commit, dispatch }, time) {
      if (!state.player) {
        console.warn("无法执行seekTo：播放器未初始化");
        return;
      }

      // 获取当前视频的时间范围
      const currentVideo = state.currentVideoInfo;

      // 使用简化的判断逻辑
      const startTime = isValidNumber(currentVideo.startTime)
        ? Number(currentVideo.startTime)
        : isValidNumber(currentVideo.start_time)
        ? Number(currentVideo.start_time)
        : 0;

      // 如果结束时间有效，使用它，否则使用视频总时长
      const endTime = isValidNumber(currentVideo.endTime)
        ? Number(currentVideo.endTime)
        : isValidNumber(currentVideo.end_time)
        ? Number(currentVideo.end_time)
        : state.player.duration;

      // 确保时间在有效范围内
      const validTime = Math.max(startTime, Math.min(endTime, time));

      console.log("执行seekTo操作: ", {
        请求时间: time,
        有效时间: validTime,
        起始时间: startTime,
        结束时间: endTime,
        当前时间: state.player.currentTime,
        视频状态: state.videoState,
      });

      try {
        // 设置播放时间
        state.player.currentTime = validTime;

        // 更新进度
        if (endTime > startTime) {
          const progress = (validTime - startTime) / (endTime - startTime);
          commit("setProgress", progress);
        }

        // 确保在单视频模式下跳转后视频处于播放状态
        if (!state.isPlaylistMode && state.videoState !== "playing") {
          console.log("单视频模式: 确保跳转后视频处于播放状态");
          commit("setVideoState", "playing");
          commit("setShowVideoControls", false);
          dispatch("playVideo");
        }
      } catch (error) {
        console.error("seekTo操作失败:", error);
      }
    },

    // 跳转到指定进度 (0-1)
    seekToProgress({ state, dispatch }, progress) {
      if (!state.player || !state.currentVideoInfo) return;

      // 获取当前视频的时间范围，使用简化的判断逻辑
      const currentVideo = state.currentVideoInfo;
      const startTime = isValidNumber(currentVideo.startTime)
        ? Number(currentVideo.startTime)
        : isValidNumber(currentVideo.start_time)
        ? Number(currentVideo.start_time)
        : 0;
      const endTime = isValidNumber(currentVideo.endTime)
        ? Number(currentVideo.endTime)
        : isValidNumber(currentVideo.end_time)
        ? Number(currentVideo.end_time)
        : state.player.duration;

      // 根据进度计算时间
      const targetTime = startTime + progress * (endTime - startTime);

      // 跳转到计算出的时间点
      dispatch("seekTo", targetTime);
    },

    // 切换到下一个视频
    nextVideo({ state, commit, dispatch }, isManualTrigger = false) {
      if (!state.isPlaylistMode) {
        // 如果不是播放列表模式，前进seekStep秒
        // 从配置中获取seekStep，默认为5秒
        const seekStep = state.seekStep || 5;
        if (state.player) {
          // 获取当前视频的起始和结束时间，使用简化的判断逻辑
          const currentVideo = state.currentVideoInfo;
          const endTime = isValidNumber(currentVideo.endTime)
            ? Number(currentVideo.endTime)
            : isValidNumber(currentVideo.end_time)
            ? Number(currentVideo.end_time)
            : null;

          let newTime = state.player.currentTime + seekStep;

          // 如果设置了结束时间，确保不超过结束时间
          if (isValidNumber(endTime)) {
            newTime = Math.min(newTime, endTime);
          }

          console.log(
            "单视频前进按钮: 当前时间",
            state.player.currentTime,
            "跳转到",
            newTime
          );

          // 设置新的播放时间
          dispatch("seekTo", newTime);

          // 确保视频处于播放状态
          if (state.videoState !== "playing") {
            commit("setVideoState", "playing");
            commit("setShowVideoControls", false);
            dispatch("playVideo");
          }
        }
        return;
      }

      // 视频加载中，不执行切换
      if (state.isVideoLoading) {
        console.log("视频加载中，暂不执行切换");
        return;
      }

      // 标记视频为加载中
      commit("setVideoLoading", true);

      // 如果是播放列表模式，切换到下一个视频
      let nextIndex = state.currentVideoIndex;
      if (nextIndex < state.videoList.length - 1) {
        nextIndex++;
      } else {
        nextIndex = 0; // 循环到第一个视频
      }

      // 如果没有有效的下一个视频，返回
      if (!state.videoList[nextIndex]) {
        console.error("没有找到下一个有效视频");
        commit("setVideoLoading", false);
        return;
      }

      commit("setCurrentVideoIndex", nextIndex);
      const nextVideo = state.videoList[nextIndex];

      // 如果当前正在显示控制按钮，设置视频状态为 stopped
      if (state.showVideoControls) {
        commit("setVideoState", "stopped");
      }

      // 重置字幕相关状态，确保新视频有正确的字幕
      try {
        if (window.emitter && typeof window.emitter.emit === "function") {
          console.log("发送字幕重置通知，确保新视频加载正确字幕");
          window.emitter.emit("dialog:reset-subtitle-state");
        }
      } catch (e) {
        console.error("发送字幕重置事件失败:", e);
      }

      // 切换视频
      commit("setCurrentVideoInfo", nextVideo);
      commit("setProgress", 0); // 重置进度

      // 通知播放器切换视频，使用命名空间
      // 如果是手动触发的，则强制自动播放，否则根据autoPlayNext设置
      emitter.emit(getDialogEventName(EVENTS.CHANGE_VIDEO), {
        ...nextVideo,
        autoPlay: isManualTrigger ? true : state.autoPlayNext,
      });

      // 添加调试日志
      console.log("视频已切换至:", {
        视频索引: nextIndex,
        视频名称: nextVideo.video_name || "未命名视频",
        字幕源: {
          en: nextVideo.en_captions,
          cn: nextVideo.cn_captions,
        },
      });
    },

    // 切换到上一个视频
    prevVideo({ state, commit, dispatch }, isManualTrigger = false) {
      if (!state.isPlaylistMode) {
        // 如果不是播放列表模式，后退seekStep秒
        // 从配置中获取seekStep，默认为5秒
        const seekStep = state.seekStep || 5;
        if (state.player) {
          // 获取当前视频的起始和结束时间，使用简化的判断逻辑
          const currentVideo = state.currentVideoInfo;
          const startTime = isValidNumber(currentVideo.startTime)
            ? Number(currentVideo.startTime)
            : isValidNumber(currentVideo.start_time)
            ? Number(currentVideo.start_time)
            : 0;

          let newTime = state.player.currentTime - seekStep;

          // 确保不小于起始时间
          newTime = Math.max(newTime, startTime);

          console.log(
            "单视频后退按钮: 当前时间",
            state.player.currentTime,
            "跳转到",
            newTime
          );

          // 设置新的播放时间
          dispatch("seekTo", newTime);

          // 确保视频处于播放状态
          if (state.videoState !== "playing") {
            commit("setVideoState", "playing");
            commit("setShowVideoControls", false);
            dispatch("playVideo");
          }
        }
        return;
      }

      // 视频加载中，不执行切换
      if (state.isVideoLoading) {
        console.log("视频加载中，暂不执行切换");
        return;
      }

      // 标记视频为加载中
      commit("setVideoLoading", true);

      // 如果是播放列表模式，切换到上一个视频
      let prevIndex = state.currentVideoIndex;
      if (prevIndex > 0) {
        prevIndex--;
      } else {
        prevIndex = state.videoList.length - 1; // 循环到最后一个视频
      }

      // 如果没有有效的上一个视频，返回
      if (!state.videoList[prevIndex]) {
        console.error("没有找到上一个有效视频");
        commit("setVideoLoading", false);
        return;
      }

      commit("setCurrentVideoIndex", prevIndex);
      const prevVideo = state.videoList[prevIndex];

      // 如果当前正在显示控制按钮，设置视频状态为 stopped
      if (state.showVideoControls) {
        commit("setVideoState", "stopped");
      }

      // 重置字幕相关状态，确保新视频有正确的字幕
      try {
        if (window.emitter && typeof window.emitter.emit === "function") {
          console.log("发送字幕重置通知，确保新视频加载正确字幕");
          window.emitter.emit("dialog:reset-subtitle-state");
        }
      } catch (e) {
        console.error("发送字幕重置事件失败:", e);
      }

      // 切换视频
      commit("setCurrentVideoInfo", prevVideo);
      commit("setProgress", 0); // 重置进度

      // 通知播放器切换视频，使用命名空间
      // 如果是手动触发的，则强制自动播放，否则根据autoPlayNext设置
      emitter.emit(getDialogEventName(EVENTS.CHANGE_VIDEO), {
        ...prevVideo,
        autoPlay: isManualTrigger ? true : state.autoPlayNext,
      });

      // 添加调试日志
      console.log("视频已切换至:", {
        视频索引: prevIndex,
        视频名称: prevVideo.video_name || "未命名视频",
        字幕源: {
          en: prevVideo.en_captions,
          cn: prevVideo.cn_captions,
        },
      });
    },

    // 处理视频播放或重播
    handlePlayOrReplay({ state, commit, dispatch }) {
      if (!state.player) return;

      if (state.videoState === "stopped") {
        commit("setVideoState", "playing");
        commit("setShowVideoControls", false);

        // 获取当前视频信息
        const currentVideo = state.currentVideoInfo;
        // 使用简化的判断逻辑
        const hasStartTime =
          isValidNumber(currentVideo.startTime) ||
          isValidNumber(currentVideo.start_time);

        // 如果设置了有效的起始时间，先跳转到起始时间
        if (hasStartTime) {
          const startTime = isValidNumber(currentVideo.startTime)
            ? Number(currentVideo.startTime)
            : Number(currentVideo.start_time);
          console.log("设置视频播放起始时间:", startTime);
          dispatch("seekTo", startTime);
        } else {
          console.log("未设置有效的起始时间，从0开始播放");
        }

        // 播放视频
        dispatch("playVideo");
      } else if (state.videoState === "ended" || state.playState === PLAY_STATE.ended) {
        // 重新播放当前视频
        commit("setVideoState", "playing");
        commit("changePlayState", PLAY_STATE.play);
        commit("setShowVideoControls", false);

        // 获取当前视频信息
        const currentVideo = state.currentVideoInfo;
        // 使用简化的判断逻辑
        const startTime = isValidNumber(currentVideo.startTime)
          ? Number(currentVideo.startTime)
          : isValidNumber(currentVideo.start_time)
          ? Number(currentVideo.start_time)
          : 0;

        console.log("重新播放视频，起始时间:", startTime);
        dispatch("seekTo", startTime);

        // 播放视频
        dispatch("playVideo");
      } else {
        // 处理其他状态（如暂停）：切换到播放状态
        commit("setVideoState", "playing");
        commit("setShowVideoControls", false);
        dispatch("playVideo");
      }
    },

    // 处理视频结束事件
    handleVideoEnded({ state, commit, dispatch }) {
      // 先设置视频状态为已结束
      commit("setVideoState", "ended");

      // 记录日志以便调试
      console.log("视频结束事件处理: ", {
        是否播放列表模式: state.isPlaylistMode,
        是否自动播放下一个: state.autoPlayNext,
      });

      // 判断是否自动播放下一个视频
      if (state.autoPlayNext) {
        if (state.isPlaylistMode) {
          // 如果是播放列表模式，且设置了自动播放下一个，则切换到下一个视频
          // 这里是自动切换，不是手动触发
          dispatch("nextVideo", false);
        } else {
          // 单视频情况下，如果设置了自动播放，则循环播放当前视频
          console.log("单视频循环播放");
          // 获取当前视频信息
          const currentVideo = state.currentVideoInfo;
          // 使用简化的判断逻辑
          const startTime = isValidNumber(currentVideo.startTime)
            ? Number(currentVideo.startTime)
            : isValidNumber(currentVideo.start_time)
            ? Number(currentVideo.start_time)
            : 0;

          // 先跳转到起始时间
          dispatch("seekTo", startTime);

          // 短暂延迟后播放视频（确保seek完成）
          setTimeout(() => {
            // 更新视频状态为播放中
            commit("setVideoState", "playing");
            commit("setShowVideoControls", false);
            // 播放视频
            dispatch("playVideo");
          }, 50);
        }
      } else {
        // 如果不自动播放下一个，显示控制按钮
        commit("setShowVideoControls", true);
      }
    },

    // 切换蒙版显示状态
    toggleMask({ commit }, visible) {
      commit("setMaskVisible", visible);

      // 当蒙版可见时，始终显示控制条
      if (visible) {
        commit("setControlBarVisible", true);
      }
    },

    // 初始化播放器配置
    initPlayerConfig({ commit }, config) {
      // 先重置部分关键状态，确保旧数据不影响新配置
      commit("setProgress", 0);
      commit("changeCurrentTime", 0);
      commit("setVideoLoading", true);

      if (config.videos && config.videos.length > 0) {
        // 过滤掉没有有效video_url的视频
        const validVideos = config.videos.filter(
          (video) => video && video.video_url
        );

        if (validVideos.length > 0) {
          // 使用过滤后的有效视频列表
          commit("setVideoList", validVideos);
          const initialIndex = config.initialVideoIndex || 0;
          // 确保索引在有效范围内
          const safeIndex = Math.min(initialIndex, validVideos.length - 1);
          commit("setCurrentVideoIndex", safeIndex);
          commit("setCurrentVideoInfo", validVideos[safeIndex]);

          // 添加日志，方便调试视频源问题
          console.log("初始化视频配置:", {
            视频数量: validVideos.length,
            初始索引: safeIndex,
            初始视频: validVideos[safeIndex],
          });
        }
      }

      // 保存seekStep配置
      if (config.seekStep !== undefined) {
        commit("setSeekStep", config.seekStep);
        console.log("设置前进后退步长:", config.seekStep, "秒");
      }

      commit(
        "setAutoPlay",
        config.autoPlay !== undefined ? config.autoPlay : false
      );
      commit(
        "setAutoPlayNext",
        config.autoPlayNext !== undefined ? config.autoPlayNext : false
      );

      // 设置初始视频状态
      commit("setVideoState", config.autoPlay ? "playing" : "stopped");
      // 根据是否自动播放决定是否显示控制按钮
      commit("setShowVideoControls", !config.autoPlay);
    },

    // 设置音量
    setVolume({ commit }, volume) {
      commit("setVolume", volume);
    },

    // 切换静音状态
    toggleMute({ state, commit }) {
      const newMuteState = !state.isMuted;
      commit("setMuted", newMuteState);
    },

    // 重置播放器状态（在弹窗关闭时调用）
    resetPlayerState({ commit, state }) {
      // 先暂停播放器（如果存在）
      if (state.player) {
        try {
          state.player.pause();
        } catch (err) {
          console.error("暂停播放器失败:", err);
        }
      }

      // 重置视频信息和列表
      commit("setCurrentVideoInfo", {});
      commit("setVideoList", []);
      commit("setCurrentVideoIndex", 0);

      // 重置播放状态
      commit("changePlayState", PLAY_STATE.loading);
      commit("setVideoState", "stopped");

      // 重置播放时间和进度
      commit("changeCurrentTime", 0);
      commit("setProgress", 0);
      commit("changeBufferTime", 0);

      // 重置其他UI状态
      commit("setShowVideoControls", false);
      commit("setMaskVisible", false);
      commit("setFullscreen", false);
      commit("setControlBarVisible", true);
      commit("setVideoLoading", false);

      console.log("已重置弹窗视频播放器状态");
    },
  },
  getters: {
    // 获取当前视频信息
    currentVideo: (state) => state.currentVideoInfo,

    // 是否为播放列表模式
    isPlaylistMode: (state) => state.isPlaylistMode,

    // 播放按钮图标类名
    playBtnIcon: (state) => {
      // 如果视频正在播放，显示暂停图标
      if (state.videoState === "playing") {
        return "icon-zanting1";
      }
      // 如果视频未开始播放，显示播放图标
      if (state.videoState === "stopped") {
        return "icon-kaishi";
      }
      // 如果视频已结束，显示重新播放图标
      if (state.videoState === "ended") {
        return "icon-xunhuan";
      }
      // 默认显示播放图标
      return "icon-kaishi";
    },

    // 获取视频有效时长 (考虑startTime和endTime)
    effectiveDuration: (state) => {
      if (!state.player || !state.currentVideoInfo) return 0;

      const startTime =
        state.currentVideoInfo.startTime ||
        state.currentVideoInfo.start_time ||
        0;
      const endTime =
        state.currentVideoInfo.endTime ||
        state.currentVideoInfo.end_time ||
        state.player.duration;

      return endTime - startTime;
    },

    // 获取当前视频的相对播放时间 (相对于startTime)
    relativeCurrentTime: (state) => {
      if (!state.player || !state.currentVideoInfo) return 0;

      const startTime =
        state.currentVideoInfo.startTime ||
        state.currentVideoInfo.start_time ||
        0;
      return Math.max(0, state.currentTime - startTime);
    },
  },
};

export default dialogVideoPlayer;
