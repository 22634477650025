import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang/index';
import * as globalProperties from './contants';
import { productCurrentTheme, productCurrentPlatform } from './contants';

// 此段代码写入main.js中
import axios from './utils/request.js'
import ElementPlus from 'element-plus'
import VueHighlightJS from 'vue-highlightjs'

// import 'element-plus/dist/index.css'
import 'highlight.js/styles/atom-one-dark.css'
import './sass-style/common/index.scss';

// 不同的项目使用不同的皮肤
if (productCurrentTheme === 'dark') {
    import('./sass-style/theme/dark.scss');
}
else if (productCurrentTheme === 'light') {
    import('./sass-style/theme/light.scss');
}





import router from './router/index'
import store from './store';
import { DialogService } from './service/DialogService'
import DialogPlugin from './plugins/dialog'




const app = createApp(App)
    .use(i18n)
    .use(VueHighlightJS)
    .use(ElementPlus)
    .use(axios)
    .use(router)
    .use(store)
    .use(DialogPlugin)

// 初始化 DialogService
DialogService.init(app)

Object.assign(app.config, {
    globalProperties: { ...app.config.globalProperties, ...globalProperties },
    errorHandler(err, vm, info) {
        // `err`是错误对象
        console.error('捕获到错误:', err);
        // `vm`是发生错误的Vue实例
        console.log('发生错误的组件实例:', vm);
        // `info`是一个包含错误来源信息的字符串
        console.log('错误来源:', info);
    }
})

app.mount('#app');

document.documentElement.setAttribute('data-theme', productCurrentTheme)
document.documentElement.setAttribute('data-platform', productCurrentPlatform)