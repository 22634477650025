// 视频列表模块 - 用于在不同组件间共享视频列表数据
export default {
  namespaced: true,
  state: {
    // 视频列表
    list: [],
    // 当前视频索引
    currentIndex: -1,
    // 查询参数
    queryParams: {
      typeId: 0,
      menuId: 0,
      cursor: 1,
      limit: 2000
    }
  },
  getters: {
    // 当前视频信息
    currentVideo: state => {
      if (state.currentIndex >= 0 && state.currentIndex < state.list.length) {
        return state.list[state.currentIndex];
      }
      return null;
    },
    // 是否有上一个视频
    hasPrevious: state => state.currentIndex > 0,
    // 是否有下一个视频
    hasNext: state => state.currentIndex < state.list.length - 1,
    // 获取上一个视频ID
    previousVideoId: (state, getters) => {
      if (getters.hasPrevious) {
        return state.list[state.currentIndex - 1]?.id;
      }
      return null;
    },
    // 获取下一个视频ID
    nextVideoId: (state, getters) => {
      if (getters.hasNext) {
        return state.list[state.currentIndex + 1]?.id;
      }
      return null;
    }
  },
  mutations: {
    // 设置视频列表
    setList(state, list) {
      state.list = list;
    },
    // 添加视频到列表
    appendList(state, list) {
      state.list = [...state.list, ...list];
    },
    // 更新查询参数
    updateQueryParams(state, params) {
      state.queryParams = { ...state.queryParams, ...params };
    },
    // 设置当前视频索引
    setCurrentIndex(state, index) {
      state.currentIndex = index;
    },
    // 根据视频ID查找并设置当前索引
    setCurrentVideoById(state, videoId) {
      const index = state.list.findIndex(video => video.id == videoId);
      if (index !== -1) {
        state.currentIndex = index;
      }
    }
  },
  actions: {
    // 加载视频列表
    async loadVideoList({ commit, state }, { api, params = {} }) {
      // 更新查询参数
      commit('updateQueryParams', params);
      
      try {
        const res = await api.aiVideoList({
          type_id: state.queryParams.typeId,
          menu_id: state.queryParams.menuId,
          cursor: state.queryParams.cursor,
          limit: state.queryParams.limit
        });
        
        if (res.code === 0 && res.data) {
          if (params.append) {
            commit('appendList', res.data.msg);
          } else {
            commit('setList', res.data.msg);
          }
          return res.data;
        }
      } catch (error) {
        console.error('加载视频列表失败:', error);
      }
      return null;
    },
    
    // 设置当前视频
    setCurrentVideo({ commit, state }, videoId) {
      // 如果视频列表中没有该视频，则尝试查找
      if (state.list.findIndex(v => v.id == videoId) === -1) {
        return false;
      }
      
      commit('setCurrentVideoById', videoId);
      return true;
    },
    
    // 切换到上一个视频
    prevVideo({ getters }) {
      return getters.previousVideoId;
    },
    
    // 切换到下一个视频
    nextVideo({ getters }) {
      return getters.nextVideoId;
    }
  }
}; 