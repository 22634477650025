import { createVNode, render, getCurrentInstance } from 'vue'
import DraggableDialog from '../components/DraggableDialog.vue'

export class DialogService {
  static instance = null
  static appContext = null
  static instances = new Map() // 存储多个对话框实例

  // 初始化方法，在应用启动时调用
  static init(app) {
    this.appContext = app._context
  }

  static show(component, options = {}) {
    // 创建唯一ID，如果没有提供
    const dialogId = options.dialogId || `dialog-${Date.now()}`
    
    // 如果已经有相同ID的实例，先关闭它
    if (this.instances.has(dialogId)) {
      this.close(dialogId)
    }

    // 创建容器
    const container = document.createElement('div')
    document.body.appendChild(container)

    // 创建弹窗 vnode
    const vnode = createVNode(DraggableDialog, {
      visible: true,
      title: options.title,
      width: options.width,
      height: options.height,
      customClass: options.customClass,
      showHeader: options.showHeader ?? true,
      showFooter: options.showFooter ?? false,
      onClose: () => {
        options.onClose?.()
        this.close(dialogId)
      },
      onOpen: () => {
        options.onOpen?.()
      }
    }, {
      // 默认插槽渲染传入的组件
      default: () => createVNode(component, {
        ...options.params,
        dialogId, // 传递对话框ID
        onClose: () => {
          options.onClose?.()
          this.close(dialogId)
        }
      })
    })

    // 使用已初始化的上下文
    if (this.appContext) {
      vnode.appContext = this.appContext
    } else {
      // 尝试从当前组件实例获取上下文
      const currentInstance = getCurrentInstance()
      if (currentInstance) {
        vnode.appContext = currentInstance.appContext
      } else {
        console.warn('DialogService: No app context available')
      }
    }

    // 渲染组件
    render(vnode, container)
    
    // 保存实例
    this.instances.set(dialogId, {
      component: vnode.component,
      container
    })
    
    // 为了兼容旧代码，仍然保留单例模式的实例引用
    this.instance = vnode.component
    
    return dialogId // 返回对话框ID，便于后续操作
  }

  static close(dialogId) {
    if (!dialogId && this.instance) {
      // 兼容旧代码，关闭单例模式的实例
      render(null, this.instance.vnode.el.parentElement)
      if (this.instance.vnode.el && this.instance.vnode.el.parentElement) {
        this.instance.vnode.el.parentElement.remove()
      }
      this.instance = null
      return
    }
    
    // 关闭指定ID的对话框
    if (dialogId && this.instances.has(dialogId)) {
      const instance = this.instances.get(dialogId)
      render(null, instance.container)
      if (instance.container) {
        instance.container.remove()
      }
      this.instances.delete(dialogId)
      
      // 如果关闭的是当前单例引用的实例，清除单例引用
      if (this.instance === instance.component) {
        this.instance = null
      }
    }
  }
  
  static closeAll() {
    // 关闭所有对话框
    this.instances.forEach((instance, id) => {
      this.close(id)
    })
    this.instance = null
  }
}