const DEFAULT_CONFIG = {
  hoverSearch: true,
  clickSpeech: true,
  aiSplit: false,
  hiddenControlBar: false,
  autoPlay: false,       // 是否自动播放视频
  autoPlayNext: false,   // 是否自动播放下一个视频
  theaterAutoPlayNext: false, // 是否自动播放下一个大视频
}

const setting = {
  namespaced: true,
  state: {
    settings: localStorage.getItem('videoSettings') ? JSON.parse(localStorage.getItem('videoSettings')) : DEFAULT_CONFIG,
    keyboard: false,
    visible: false,
    subtitleTextPanelVisible: false,
    playSettingPanelVisible: false,
    subtitleOpacity: 10,
    collectTextPanelVisible: false,
    chatRobotVisible: false,
    chatRobotSetting: {
      type: 10,
      id: 1,
      extraInfo: { content: '你好' }
    }
  },
  mutations: {
    updateSettingVisible(state, data) {
      state.visible = data;
    },
    updateSetting(state, data) {
      state.settings = {
        ...state.settings,
        ...data
      }
      localStorage.setItem('videoSettings', JSON.stringify(state.settings));
    },
    updateKeyboard(state, status) {
      state.keyboard = status;
    },
    updateSubtitleTextPanel(state, status) {
      state.subtitleTextPanelVisible = status;
    },
    updatePlaySettingPanel(state, status) {
      state.playSettingPanelVisible = status;
    },
    updateSubtitleOpacity(state, status) {
      state.subtitleOpacity = status;
    },
    updateCollectTextPanel(state, status) {
      state.collectTextPanelVisible = status;
    },
    updateChatRobot(state, status) {
      state.chatRobotVisible = status;
    },
    updateChatRobotSetting(state, status) {
      state.chatRobotSetting = status;
    }
  }
}

export default setting;